<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button variant="relief-primary" @click="addPlan" class="mb-2">
        <feather-icon icon="PlusIcon" />
        {{ $t("navigation.add_button", { name: $route.meta.pageTitle }) }}
      </b-button>
    </div>
    <BsTable
      tableName="plans"
      :fields="tableFields"
      :actions="actions"
      :has_paginator="true"
      :show_filter="true"
    />
    <base-modal />
  </div>
</template>

<script>
import BsTable from "@/layouts/components/BsTable.vue";
import custom from "@/custom";
import moment from "moment";
import { BButton } from "bootstrap-vue";
import BaseModal from "@/layouts/components/BaseModal.vue";

export default {
  components: {
    BsTable,
    BButton,
    BaseModal,
  },

  data() {
    return {
      tableFields: [
        {
          key: "id",
          label: this.$t("common.table.fields.id"),
          sortable: false,
        },
        {
          key: "name",
          label: this.$t("common.table.fields.name"),
          sortable: false,
        },
        {
          key: "status",
          label: this.$t("common.table.fields.status"),
          sortable: false,
        },
        {
          key: "is_private",
          label: this.$t("plans.table.fields.private"),
          sortable: false,
        },
        {
          key: "category",
          label: this.$t("plans.table.fields.category"),
          sortable: false,
        },
        {
          key: "created_at",
          label: this.$t("common.table.fields.created_at"),
          sortable: false,
        },
        {
          key: "actions",
          label: this.$t("common.table.fields.actions"),
        },
      ],
      tableItems: [],
      perPage: 10,
      tags: "",
      actions: custom.tableActions.plans,
      totalRows: 0,
    };
  },

  // beforeMount() {
  //   this.fetchOrganization()
  // },

  created() {
    this.$root.$refs["this.$route.meta.pageTitle"] = this;
  },

  methods: {
    addPlan() {
      this.$store
        .dispatch("setModalObj", "addPlan")
        .then(() => {
          this.$store.dispatch("setActiveModal", true);
        })
        .catch(() => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>